"use client";
import { useState, useEffect, useRef } from "react"; // Import useRef
import { createClient } from "@layerzerolabs/scan-client";
import { useBridgeContext } from "@/context/bridgeProvider";
import { getTransactionReceipt } from "@wagmi/core";
import { config } from "../../../web3/config";
import { ethers } from "ethers";
import nativeTokenAbi from "../../../web3/abis/nativeTokenAbi";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import {
  LayerZeroMessageProp,
  TransferredTokensProp,
} from "../../../types/types";
import { Address, erc20Abi } from "viem";
import { readContract } from "wagmi/actions";
import { fromWei } from "../../../utils/web3BigNumbers";
import TransactionModal from "../../../components/TransactionModal/TransactionModal";

const TransactionStatusChecker = () => {
  const { account } = useWalletAccount();
  const { destinationChain } = useBridgeContext();
  const client = createClient("mainnet");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const transferEventSignature = "Transfer(address,address,uint256)";
  const transferTopicHash = ethers.utils.id(transferEventSignature);
  const iface = new ethers.utils.Interface(nativeTokenAbi);
  const [transferredTokens, setTransferredTokens] = useState<
    TransferredTokensProp[]
  >([]);
  const [layerZeroMessage, setLayerZeroMessage] =
    useState<null | LayerZeroMessageProp>(null);
  const { bridgeHash, status, setStatus, setDesTxHash, setIsDelivered } =
    useBridgeContext();

  const getBridgeTransferTx = async () => {
    if (!layerZeroMessage || !layerZeroMessage.dstTxHash) return;
    setDesTxHash(layerZeroMessage.dstTxHash as Address);
    const txReceipt = await getTransactionReceipt(config, {
      hash: layerZeroMessage.dstTxHash as Address,
      chainId: destinationChain!.id,
    });

    const newTransferredTokens: TransferredTokensProp[] = [];

    for (const log of txReceipt.logs) {
      if (log.topics[0] === transferTopicHash) {
        const parsedLog = iface.parseLog(log);

        if (parsedLog.args.to.toLowerCase() === account?.toLowerCase()) {
          const tokenAddress = log.address;
          const tokenValue = fromWei(parsedLog.args.value.toString());
          const contractName = await readContract(config, {
            abi: erc20Abi,
            address: tokenAddress,
            functionName: "name",
            chainId: destinationChain!.id,
          });

          newTransferredTokens.push({ tokenAddress, tokenValue, contractName });
        }
      }
    }

    setTransferredTokens(newTransferredTokens);
  };

  useEffect(() => {
    if (
      destinationChain &&
      bridgeHash &&
      account &&
      layerZeroMessage &&
      layerZeroMessage.dstTxHash
    ) {
      getBridgeTransferTx();
    } else {
      setTransferredTokens([]);
    }
  }, [bridgeHash, destinationChain, account, layerZeroMessage]);

  useEffect(() => {
    if (status === "DELIVERED" || status === "CONFIRMING") {
      setIsDelivered(status === "DELIVERED");
      console.log("------DELIVERED-----");
      localStorage.clear();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  }, [status]);

  useEffect(() => {
    if (!bridgeHash) return;
    const checkLayerZeroTransactionHash = async () => {
      try {
        const { messages } = await client.getMessagesBySrcTxHash(bridgeHash);
        if (messages) {
          setLayerZeroMessage(messages[0]);
          if (messages[0].status) {
            setStatus(messages[0].status);
          }
        }
      } catch (error) {
        console.error("Error checking transaction status:", error);
      }
    };

    intervalRef.current = setInterval(() => {
      checkLayerZeroTransactionHash();
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [bridgeHash]);

  return (
    <div>
      <TransactionModal transferredTokens={transferredTokens} />
    </div>
  );
};

export default TransactionStatusChecker;
