import Modal from "../common/Modal";
import { TransferredTokensProp } from "../../types/types";
import { useBridgeContext } from "@/context/bridgeProvider";
import { TxExplorerUrl } from "../../constants/contractAddresses";
import { useEffect } from "react";

interface TransactionModalProps {
  transferredTokens: TransferredTokensProp[];
}

const TransactionModal = ({ transferredTokens }: TransactionModalProps) => {
  const {
    status,
    desTxHash,
    destinationChain,
    isTxModalOpen,
    setIsTxModalOpen,
  } = useBridgeContext();

  const handelOpenModal = () => {
    setIsTxModalOpen(!isTxModalOpen);
  };

  function getTxUrl(chainId: number, txHash: string) {
    let explorerUrl = TxExplorerUrl[chainId];
    return `${explorerUrl}${txHash}`;
  }

  return (
    <div className="select-none">
      <Modal
        titleLeft="Transaction Details"
        isOpen={isTxModalOpen}
        closeModalHandler={handelOpenModal}
        size="medium"
        modalClassName="transaction"
      >
        <div className="modalBody">
          <div className="mb-5">
            {!status ? (
              <div>
                <p>
                  {" "}
                  Transaction Status:{" "}
                  <span className="text-orange-500">Bridging</span>
                </p>
                <div className="mt-2">
                  Waiting for the transaction to complete.
                </div>
              </div>
            ) : (
              <div className="flex justify-between">
                <p>
                  Transaction Status:{" "}
                  <span
                    className={`${
                      status === "INFLIGHT" || status === "FAILED"
                        ? "text-orange-500"
                        : "text-green-500"
                    }`}
                  >
                    {status === "INFLIGHT"
                      ? "Bridging"
                      : status === "FAILED"
                      ? "FAILED"
                      : "DELIVERED"}
                  </span>
                </p>
                <div>
                  {destinationChain &&
                    destinationChain!.id &&
                    desTxHash &&
                    status &&
                    status !== "INFLIGHT" && (
                      <a
                        className="mb-5 text-[12px] hover:text-gray-500"
                        target="_blank"
                        href={getTxUrl(destinationChain!.id, desTxHash!)}
                      >
                        View on explorer
                      </a>
                    )}
                </div>
              </div>
            )}
            {status === "INFLIGHT" && (
              <div className="mt-2">
                Waiting for the transaction to complete.
              </div>
            )}
          </div>
          <div>
            {transferredTokens.length > 0 &&
              status &&
              status !== "INFLIGHT" && (
                <div className="mt-3 flex justify-between">
                  <div>
                    <div className="mb-2">You Received:</div>
                    <div className="ml-3">
                      {transferredTokens.map((item, index) => (
                        <div className="mb-2" key={index}>
                          {item.tokenValue + " " + item.contractName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransactionModal;
